:root {
  --white: #ffffff;
  --divider: #3b3b3b;
  --green: #0ac79a;
  --black: #000000;
  --gray: #666666;
  --darkGray: #606060;
  --lightGray: #8e98a8;
  --mediumGray: #333333;
  --light-black: #222222;
  --lightWhite: #e5e5e5;
  --lightGreen: #f5fbf9;
  --divider-light: #ebedef;
  --light-white: #f3f7f6;
}
.slide-container {
  height: 100px;
  position: relative;
  margin-top: 10px;
  width: 100%;
}
.slide-container img {
  max-width: 115px;
  margin: 0 50px;
  object-fit: contain;
  max-height: 60px;
}

.slide-item-container {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-item-container .slide {
  display: inline-block;
  padding-left: calc(1500px);
  animation: slide-item-container 24s linear infinite;
}

@keyframes slide-item-container {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  height: 100%;
  color: var(--white);
}

html {
  scroll-behavior: smooth;
}
html,
#root {
  height: 100%;
}

.ant-modal-content {
  padding: 0 !important;
}
.ant-carousel .slick-dots {
  margin-top: 20px;
  bottom: 0;
  position: relative;
}
.ant-carousel .slick-dots li button {
  background-color: #d9d9d9;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button {
  background-color: var(--green);
  opacity: 1;
}
.ant-carousel .slick-dots {
  justify-content: start;
}

.active {
  border-bottom: 2px solid var(--green);
}
a.active {
  font-weight: 600;
}
.notfound {
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 50%),
    url("./assets/comingbg.jpg") center/cover;
}
.freebcol .fitImage {
  padding: 0px 0;
}
#five {
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .notfound {
    height: 100%;
  }
  .slide-container {
    margin-top: 30px;
    height: 150px;
  }
  .slide-container img {
    width: 150px;
  }
}
.ant-result-title {
  color: #fff !important;
}
.ant-result-subtitle {
  color: #fff !important;
}
/* Typography */
.poppins-thin {
  font-weight: 100;
  font-style: normal;
}
a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.ant-tooltip {
  max-width: 800px;
}
.ant-input {
  height: 40px;
  border-color: #e5e5e5;
  border-radius: 8px;
}
.ant-form-vertical .ant-form-item-label {
  padding: 0 0 3px;
}

.poppins-extralight {
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-weight: 900;
  font-style: italic;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

/*  */

.sticky-block {
  max-height: none;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
}

.sticky-wrap {
  max-width: 600px;
  position: relative;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-halves.feature-cards-grid {
  grid-column-gap: 60px;
}

.grid-halves.feature-cards-grid.two-columns {
  grid-column-gap: 80px;
  grid-template-columns: 1fr 1fr;
}

.stick-content-wrap {
  width: 100%;
  max-width: 610px;
  grid-column-gap: 24px;
  grid-row-gap: 0;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}
@media screen and (max-width: 767px) {
  .grid-halves.feature-cards-grid.two-columns {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }
}

.cd-intro {
  overflow: unset;
  padding: 20px 0 10px;
}
