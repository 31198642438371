.socialContainer {
  border-bottom: 1px solid var(--divider);
  padding: 12px 90px;
}

.socialContainer h5 {
  font-weight: 500;
}

.navBar {
  padding: 15px 86px;
}
.serviceNav {
  display: flex;
  align-items: center;
}
.serviceNav svg {
  margin-top: 22px;
  margin-left: 4px;
}

.serviceNav svg path {
  fill: #000;
}

@media (max-width: 920px) {
  .navBar {
    padding: 15px;
  }
}

.header {
  position: absolute;
  z-index: 100;
  width: 100%;
}

@media (max-width: 768px) {
  .navBar {
    padding: 10px;
    padding-right: 15px;
  }
  .socialContainer {
    border-bottom: 1px solid var(--divider);
    padding: 15px 10px;
  }
}

@media (max-width: 530px) {
  .socialContainer {
    padding: 10px;
  }
  .socialContainer h5 {
    font-size: 10px;
  }
}

/* Tree select css */
.container {
  position: absolute;
  width: 100%;
  left: 0;
  top: -80px;
  margin-top: 180px;
  background-color: #fff;
  padding: 20px;
  padding-top: 0px;
  padding-right: 0px;
  box-shadow: 0px 4px 14px 0px #0000001a;
  border-radius: 3px;
}
.menu {
  position: relative;
  width: 100%;
  padding-top: 20px;
}

.desktopMenu {
  display: block;
}
.mobileMenu {
  display: none;
}

@media (max-width: 920px) {
  .mobileMenu {
    display: block;
  }
  .desktopMenu {
    display: none;
  }
}

.menu h6 {
  color: var(--black);
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
}

.closeIcon {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 4px;
  top: 4px;
}
.servicesContainerMenu {
  margin-top: 10px;
  padding: 20px;
  background-color: var(--light-white);
  border-radius: 4px;
  margin-right: 20px;
}
.servicesContainer:nth-child(2) {
  margin-top: 20px;
}

.servicesContainer h4 {
  color: var(--black);
  font-weight: 600;
  font-size: 14px;
}
.servicesContainer h6 {
  color: var(--black);
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
}
