.primaryBtn {
  padding: 12px 24px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    270deg,
    #0ac79a 2.26%,
    #0ac79a 2.27%,
    #0073f2 96.61%
  );
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: var(--white);
  cursor: pointer;
  transition: transform 0.5s ease;
}
.secondaryBtn {
  padding: 12px 24px;
  background-color: var(--white);
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
  cursor: pointer;
  transition: transform 0.3s ease;
  height: 45px;
  display: flex;

  justify-content: center;
  align-items: center;
}

.primaryBtn:hover {
  transform: scale(1.15);
}
.secondaryBtn:hover {
  transform: scale(1.15);
}
@media (max-width: 500px) {
  .secondaryBtn {
    width: 200px;
  }
  .primaryBtn {
    width: 200px;
  }
}
