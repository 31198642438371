.carouselContainer {
  background-color: transparent;
  border-radius: 50px;
  overflow: hidden;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -80px;
}

.tagLineContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.companyDataContainer {
  box-shadow: 0px 4px 14px 0px #0000001a;
  background-color: var(--white);
  border-radius: 16px;
  width: 75%;
  padding: 40px;
  z-index: 100;
}

.tagLineContainer {
  height: 100px;
}

@media (max-width: 1100px) {
  .tagLineContainer {
    height: 200px;
  }
}
@media (max-width: 768px) {
  .tagLineContainer {
    height: 180px;
  }
  .companyDataContainer {
    width: 100%;
    padding: 20px;
  }
  .carouselContainer {
    margin-top: -100px;
  }
}

.companyDataContainer h2 {
  font-weight: 700;
  color: var(--light-black);
  font-family: "Inter", sans-serif;
}
.companyDataContainer h6 {
  font-weight: 400;
  color: var(--gray);
  font-family: "Inter", sans-serif;
  margin-top: 5px;
}

.companyItem {
  text-align: center;
  height: 100%;
  position: relative;
  padding: 5px 0;
}
.companyItem span {
  color: var(--black) !important;
}

.companyItem::before {
  content: "";
  border-left: 1px solid var(--lightGray);
  height: 70%;
  margin-left: 16px;
  position: absolute;
  right: 0;
  top: 15%;
}
.companyItem:last-child::before {
  border-left: none;
}

@media (max-width: 768px) {
  .companyItem::before {
    border-left: none;
  }
}

.imagesStack {
  height: 100%;
  position: relative;
}
.imagesStack img {
  width: 50%;
  height: 100%;
  object-fit: contain;
}
.imagesStack img:nth-child(2) {
  position: absolute;
  margin-bottom: -15px;
  margin-left: -50px;
  right: 50px;
  bottom: -20px;
}

.imageStackContainer {
  height: 100%;
  position: relative;
}
.imageStackContainer img {
  width: 40%;
  height: 100%;
  object-fit: contain;
}
.imageStack {
  display: flex;
  justify-content: flex-end;
}

.imageStackContainer img:nth-child(1) {
  position: absolute;
  right: 60px;
  /* scale: 1.3; */
  width: 55%;
  height: 100%;
}

.card {
  height: 100% !important;
  padding: 20px;
  border: 1px solid var(--lightWhite);
  border-radius: 8px;
  background-color: var(--lightGreen);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card h3 {
  margin-top: 0px;
  font-weight: 700;
  color: var(--black);
}

.card p {
  margin-bottom: 0;
  color: var(--mediumGray);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.tapeContainer {
  background-color: var(--black);
  padding: 50px 86px;
  margin-top: 40px;
}
.tapeContainer h4 {
  font-weight: 600;
  text-align: right;
}
.tapeContainer span {
  font-size: 10px;
  font-weight: 500;
}
.tapeContainer h1 {
  font-size: 60px;
  font-weight: 600;
  color: var(--green);
  text-align: right;
}
.tapeContainer h4:last-child {
  font-weight: 500;
  text-align: left;
  padding-left: 150px;
  line-height: 30px;
}

.tapeContainer h4:last-child::before {
  content: "";
  border-left: 1px solid var(--lightGray);
  height: 100%;
  position: absolute;
  left: 0;
  margin-left: 90px;
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 100%;
  }
  .tapeContainer {
    padding: 10px;
  }
  .tapeContainer h1,
  .tapeContainer h4 {
    text-align: center;
  }
  .tapeContainer h4:last-child::before {
    display: none;
  }
  .tapeContainer h4:last-child {
    padding-left: 0px;
    text-align: center;
  }
  .tapeContainer h4 {
    font-weight: 600;
    text-align: center;
  }
}

.profile {
  width: 110px;
  height: 110px;
  object-fit: contain;
  margin-top: 10px;
}
.clients {
  padding: 30px 10px;
  padding-top: 0;
}

@keyframes slide {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.clients h1 {
  color: var(--black);
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 34px;
}

@keyframes scrollHor {
  0% {
    margin-left: 0%;
  }
  100% {
    margin-left: -50%;
  }
}

/* Services Container */
.servicesContainer {
  padding: 10px 92px;
}

.servicesContainer h4 {
  color: var(--green);
  font-weight: 600;
}

.servicesContainer h2 {
  color: var(--black);
  font-size: 38px;
  font-weight: 800;
}

@media (max-width: 768px) {
  .clients h1 {
    margin-bottom: 14px;
  }

  .servicesContainer {
    padding: 10px;
  }
  .servicesContainer h2 {
    font-size: 30px;
    line-height: 35px;
  }
}
