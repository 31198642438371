.socialIcons svg {
  cursor: pointer;
  transition: transform 0.5s ease;
}
.socialIcons svg:hover path {
  fill: var(--green);
}
.socialIcons svg:hover {
  scale: 1.2;
  fill: var(--green);
  stroke: var(--green);
}
