.contactContainer {
  background-color: #fff;
  position: relative;
  padding: 50px 80px;
  padding-bottom: 60px;
}
.contactContainer h1 {
  font-size: 38px;
  font-weight: 600;
  color: var(--black);
}
.contactContainer h2 {
  font-weight: 600;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .contactContainer {
    padding: 20px;
  }
  .contactContainer h1 {
    font-size: 28px;
  }
}

.getInTouch {
  padding: 20px;
  background-color: #f9f9f9;
  color: #000;
  border-radius: 16px;
}
.getInTouch h6 {
  margin-top: 3px;
  line-height: 20px;
}
.getInTouch h4 {
  font-weight: 500;
  text-align: left;
  line-height: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.email {
  height: 150px;
  object-fit: contain;
}
.title {
  font-size: 4rem;
  margin-bottom: 10px;
  color: var(--green);
  font-weight: bold;
}

.message {
  font-size: 1.8rem;
  margin-bottom: 20px;
  margin-bottom: 50px;
}

.info {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.closeBtn {
  width: 30%;
}
