.companyDataContainer {
  background-color: var(--white);
  padding: 36px 68px;
  width: 90%;
  margin-top: 50px;
  margin: auto;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 80px;
}

.companyDataContainer h2 {
  font-weight: 700;
  color: var(--light-black);
  font-family: "Inter", sans-serif;
}
.companyDataContainer h6 {
  font-weight: 400;
  color: var(--gray);
  font-family: "Inter", sans-serif;
  margin-top: 5px;
}

.companyItem {
  text-align: center;
  height: 100%;
  position: relative;
}

.companyItem::before {
  content: "";
  border-left: 1px solid var(--lightGray);
  height: 70%;
  margin-left: 16px;
  position: absolute;
  right: 0;
  top: 15%;
}
.companyItem:last-child::before {
  border-left: none;
}

@media (max-width: 768px) {
  .companyDataContainer {
    padding: 10px;
    width: 90%;
  }
  .companyItem::before {
    border-left: none;
  }
}
