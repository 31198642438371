.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 90%;
  background-size: cover;
  background-position: center;
}

.containerSm {
  height: 50%;
}

.container span {
  color: var(--green);
}
.container h1 {
  font-weight: 700;padding-top: 70px;
}

.container > h2 {
  font-weight: 400;
  text-align: center;
  margin: 20px 0;
  width: 70%;
}

@media (max-width: 768px) {
  .container h1 {
    font-size: 28px;
    text-align: center;
    line-height: 1.3;
    margin-top: 60px;
  }
  .container > h2 {
    margin: 0px 0;
    width: 100%;
    font-size: 16px;
    margin: 20px 0;
    line-height: 1.5;
  }
}
