/* Secondary Card */
.serviceContainer {
  color: #000;
  padding:70px 90px;
  padding-bottom: 90px;
  background-color: #f1f3f7;
}

.web {
  margin-top: 20px;
}
.text-center{ text-align: center;}
.webtop{ margin-top: 40px;}
.serviceContainer h1 {
  font-size: 38px;
    text-align: center;
    font-weight: 800;
    line-height: 48px;
    margin: 0 0 10px 
}
.appDev {
  background-color: #fff; padding-top: 0;
}

.serviceContainer > h5 {
  font-weight: 500;
    text-align: center;
    margin:0px 0 50px;
    line-height: 24px; position: relative;
}

.app {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 100000;
}
.appDevContainer {
  color: #000;
  padding:60px 90px;
  background-color: #fff;
}
.appDev.serviceContainer h1 {
  text-align: left;
}

.appDevContainer h4 {
  color: var(--green);
  text-align: center;
  font-weight: 600;
}
.appDevContainer h1 {
  text-align: center; font-size: 38px; line-height: 46px; font-weight: 800;
}
.appDevContainer h5 {
  font-weight: 500;
  text-align: center;
  line-height: 24px;
}

.cardContainer h1 {
  text-align: left;
  font-weight:800;
  line-height: 50px;  font-size:38px;
}
.cardContainer {
  padding: 20px 90px;
  color: var(--black);
}
.text-center{ text-align: center;}
.cardContainer h2 {
  font-weight: 700;
  line-height: 32px;
  margin: 0px 0;
}
.dataimg{ margin: 20px 0 0; border-radius: 6px;} 
.standimg{ margin: 20px 0 0; border-radius: 6px;} 
.cardContainer h6 {
  font-weight: 500;
  line-height: 24px;
  margin-top: 20px; font-size: 14px;
}

.appDev.serviceContainer > h5 {
   
  text-align: left;
}

.aiBottom {
  padding: 20px 90px;
  color: var(--black);
}

.footer {
  color: #000;
  text-align: center;
  padding: 100px 0;
}
.footer h2 {
  margin-top: 0;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 50px;
}
.footer img {
  
  max-width: 200px;
  max-height: 80px;
  border: 1px solid #ddd;
  padding: 10px 30px;
  border-radius: 6px;
}
.tech {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 40px;
}
@media (max-width: 768px) {
  .footer {
    padding: 40px 0;
  }
  .tech {
    gap: 20px;
  }
  .appDevContainer {
    padding: 20px;
  }
  .cardContainer {
    padding: 20px;
  }
  .serviceContainer {
    color: #000;
    padding: 20px;
    background-color: #f1f3f7;
  }
  .cardContainer h2 {
    font-size: 20px;
  }
  .serviceContainer h1 {
    font-size: 24px;
  }

  .footer h1 {
    font-size: 24px;
  }
  .footer h2 {
    font-size: 20px;
  }

  .serviceContainer h2 {
    font-size: 20px;
  }
  .serviceContainer h5 {
    font-size: 12px;
  }

  .cardContainer h1 {
    font-size: 24px;
  }
  .aiBottom {
    padding: 20px;
  }
  .aiBottom h1 {
    font-size: 24px;
  }
  .appDevContainer h1 {
    font-size: 24px;
  }
  .appDev.serviceContainer > h5 {
    width: 100%;
  }
}
