.navLinks {
  display: flex;
}
.navLinks a {
  color: var(--white);
  margin-left: 30px;
  padding-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
}
.navLinksVertical {
  flex-direction: column;
}

.navLinksVertical a {
  border-bottom: none;
  margin: 0;
}

.dropdownItem h6 {
  color: var(--black);
  font-weight: 400;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdownItem h3 {
  color: var(--black);
  font-weight: 600;
}
.navTitle {
  background-color: #fff;
  padding: 20px 25px;
  border-radius: 8px;
}

.navTitle h3 {
  color: var(--black);
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .navLinks a {
    padding-bottom: 0px;
    margin: 0px;
    margin-left: 15px;
  }
}
