.container {
  background-color: var(--black);
}
.footerContainer {
  background-color: var(--black);
  padding: 50px 86px 30px;
}
.copyright {
  border-top: 1px solid var(--divider);
  width: 100%;
  padding: 15px 0;
  text-align: center;
}
.footerContainer h4 {
  font-weight: 600;
  text-align: right;
}
.footerContainer h1 {
  font-size: 60px;
  font-weight: 600;
  color: var(--green);
  text-align: right;
}
.footerContainer h4:last-child {
  font-weight: 500;
  text-align: left;
  padding-left: 150px;
}

.footerContainer h5 {
  font-weight: 500;
  margin: 20px 0 35px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .footerContainer {
    background-color: var(--black);
    padding: 20px;
  }
  .footerContainer h5 {
    margin: 10px 0;
  }
}
.quickLinks h6 {
  font-weight: 400;
  font-size: 13px;
  margin-top:15px;
  cursor: pointer;
  color: #fff;
}
