.card {
  background-color: #fff;
  position: relative;
  padding: 62px 90px;
  overflow: hidden;
}
.text-center{ text-align: center;}
.card h1 {
  font-size: 38px;
  font-weight: 700;
  color: var(--light-black);
  line-height:46px;
}
.fitImage1 img {
  width: 100%;
  height: 100%;
  object-fit: contain; border-radius: 10px;
}
.fitImage1{ padding-bottom: 0px;}
.card h4,
.card ul {
  list-style: none;
  font-weight: 600;
  margin-top: 20px;
  color: var(--green);
}
.card ul li {
  color: var(--black);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 24px;
}

.reverse {
  display: flex;
  flex-direction: row-reverse;
}

.reverse h3 {
  color: var(--black);
  font-weight: 600;
  line-height: 20px;
}
.reverse h6 {
  color: var(--black);
  font-weight: 400;
}

@media (max-width: 768px) {
  .card {
    padding: 50px 10px;
    padding-bottom: 10px;
  }
  .card h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

/* OTHER PAGES CUSTOME CLASSES CSS */

.testimonials {
  padding-right: 0px;
}

.fitImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.colored {
  background-color: #f1f3f7;
}

/* Primary Card */
.primaryCard {
  color: #000;
}
.primaryCard h2 {
  font-weight: 800;
  text-decoration: none;
  text-underline-offset: 0px; position: relative;
  text-decoration-color: var(--green); padding-bottom: 10px; font-size: 23px;
}
.text-center{ text-align: center;}
.primaryCard h2:after{ position: absolute; left: 0; width: 80%; height: 4px; bottom: -7px; background-color: #0AC79A;  content: "";}
.primaryCard h5 {
  font-weight: 400;
  margin: 20px 0;
  line-height: 24px;
}
.list:has(span) {
  line-height: 1;
}

/* Primary Card */
.secondaryCard {
  color: #000;
  margin-top: 30px;
}
.secondaryCard:first-child {
  margin-top: 0px;
}
.secondaryCard h2 { 
  line-height: 1.8 !important;
}
.secondaryCard h5 {
  font-weight:500;
  margin: 0px 0;
  text-align: left;
  line-height: 27px; font-size: 14px;
}
